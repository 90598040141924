export const indexPageData = {
  "en":{
    sectionHero: {
      header: "Transform your user experience with voice",
      tag: "Build powerful experiences in 3 minutes on Alexa, Google Assistant, Siri and everywhere else",
      heroBtn: "Learn more",
    },
    sectionAny: {
      header: "Delight your customer with conversation anytime, anywhere",
      tag: "Build on all platforms and device right here, in one place",
      channels: {
        google: "Google Assistant",
        tencent: "Tencent Xiaowei",
        alexa: "Alexa",
        siri: "Siri",
        cortana: "Cortana",
        xiaomi: "Xiaomi",
        bixby: "Bixby",
        whatsapp: "Whatsapp",
        line: "Line",
        fb: "Messenger",
        wechat: "Wechat",
        telegram: "Telegram",
        kakao: "Kakao",
        apple: "Apple business chat"
      },
    },
    sectionDashboard: {
      header: "Create what you imagine",
      pt1: "Use words and pictures - no coding or tech knowhow needed",
      pt2: "Save time with templates organized by industry and use cases",
      pt3: "Test, publish and track analytics"
    },
    sectionCase: {
      header: "A voice for everyone and every business",
      tag: "Get started with templates, then customize for your professional or personal needs.",
      case1: "Virtual Assistant - CoCoon",
      case2: "Online Store - Shopify",
      case3: "Personal - Catholic Faith",
      case4: "Information - IGSO",
      case5: "Mobile App - Outwhiz",
      case6: "Survey - Singapore Tourism Board",
      trustedBy: "trusted by"
    },
    sectionAPI: {
      header: "All your voice development pains solved, in a single API",
      features: [
        "Multi-platform",
        "Analytics",
        "Multi-device",
        "NLP simplified or no NLP",
        "Multi-language",
        "Scalable UI",
        "Voice shortcuts for mobile apps",
        "Transactons, push notification + more"
      ],
      button: "Delight for developers"
    },
    sectionTestimonal: {
      quote: "We are able to do in minutes what usually takes days and weeks in voice.",
      author: "Erica Ma | CoCoon Hong Kong",
      button: "Play CoCoon Hong Kong story"
    },
    sectionEmail: {
      header: "Subscribe to our newsletter",
      placeholder: "Email Address",
      button: "Subscribe"
    }
  },
  "zh":{
    sectionHero: {
      header: "语音智慧串联生活 创建自然对话体验",
      tag: "3分钟创建个人专属语音助手，轻松上线腾讯小微、叮当、小度、小爱同学、阿里精灵、Alexa、Google助理、Siri等语音平台。",
      heroBtn: "立即开始",
    },
    sectionAny: {
      header: "跨平台语音助手工具 让你时刻保持在线",
      tag: "Delight的网页介面，让您一键发布语音内容，跨平台同步更新。",
      channels: {
        google: "Google助理",
        tencent: "腾讯小微",
        alexa: "Alexa",
        siri: "Siri",
        cortana: "Cortana",
        xiaomi: "小爱同学",
        bixby: "Bixby",
        whatsapp: "Whatsapp",
        line: "Line",
        fb: "Messenger",
        wechat: "微信",
        telegram: "Telegram",
        kakao: "Kakao",
        apple: "Apple business chat"
      },
    },
    sectionDashboard: {
      header: "创您所想   写您所说",
      pt1: "純图文制作，无须编程",
      pt2: "多元化专案范本，制作简易快捷",
      pt3: "一站式测试、发布及数据分析"
    },
    sectionCase: {
      header: "为所有人而设 探索语音无限可能",
      tag: "Delight提供多种专案范本，不同行业均可自由运用，随心设计属于您的智能助手。",
      case1: "办公室助手 - 浩观语音导赏",
      case2: "网店助手 -  Shopify语音网店",
      case3: "私人助手 - 学习信仰",
      case4: "资讯助手 - IGSO农产品语音维基",
      case5: "App应用助手 - Outwhiz手机App捷径",
      case6: "问卷助手 - 新加坡旅游局的语音问卷",
      trustedBy: "值得信任"
    },
    sectionAPI: {
      header: "统一语音API接口  一站式多平台接入",
      features: [
        "跨平台",
        "数据分析",
        "适用于不同语音设备",
        "无须处理NLP",
        "多语言频道",
        "自适应设计",
        "手机APP语音捷径",
        "语音支付、语音通知推送及更多"
      ],
      button: "开发者"
    },
    sectionTestimonal: {
      quote: "「原本要几个月才办得到，现在3分钟就搞定。」",
      author: "马殷｜香港浩观",
      button: "观看浩观故事"
    },
    sectionEmail: {
      header: "订阅通讯",
      placeholder: "您的电邮",
      button: "订阅"
    }
  },
  "zh-hk":{
    sectionHero: {
      header: "語音智慧串聯生活\n 創建自然對話體驗",
      tag: "3分鐘創建專屬語音助理，輕鬆登陸Alexa、Google助理、Siri、Bixby等各大平台。",
      heroBtn: "立即開始",
    },
    sectionAny: {
      header: "跨平台語音助理工具\n 讓你時刻保持在線",
      tag: "Delight的網頁介面，讓您一鍵發佈語音內容，跨平台同步更新。",
      channels: {
        google: "Google助理",
        tencent: "騰訊小微",
        alexa: "Alexa",
        siri: "Siri",
        cortana: "Cortana",
        xiaomi: "小愛同學",
        bixby: "Bixby",
        whatsapp: "Whatsapp",
        line: "Line",
        fb: "Messenger",
        wechat: "微信",
        telegram: "Telegram",
        kakao: "Kakao",
        apple: "Apple business chat"
      },
    },
    sectionDashboard: {
      header: "創您所想 寫您所講",
      pt1: "以文字及圖片製作，全程毋須編碼",
      pt2: "提供多種專案範本，製作簡易快捷",
      pt3: "一站式測試、發佈及數據分析"
    },
    sectionCase: {
      header: "為所有人而設 探索語音無限可能",
      tag: "Delight提供多種專案範本，不同行業均可自由運用，隨心設計屬於您的智能助理。",
      case1: "辦公室助理 - 浩觀語音導賞",
      case2: "網店助理 - Shopify 語音網店",
      case3: "私人助理 - 學習信仰",
      case4: "資訊助理 - IGSO 農產品語音維基",
      case5: "App應用助理 - Outwhiz 手機App捷徑",
      case6: "問卷助理 - 新加坡旅遊局語音問卷",
      trustedBy: "值得信任"
    },
    sectionAPI: {
      header: "一個API串聯世界  掃除所有銜接障礙",
      features: [
        "跨平台",
        "數據分析",
        "適用於不同智能設備",
        "簡化或毋須NLP",
        "多語頻道",
        "回應式UI",
        "手機App語音捷徑",
        "語音付款、語音通知推送及更多"
      ],
      button: "開發人員"
    },
    sectionTestimonal: {
      quote: "「原本要數個月才做到的語音助理，現在3分鐘就搞掂。」",
      author: "馬殷｜香港浩觀",
      button: "觀看浩觀故事"
    },
    sectionEmail: {
      header: "訂閱通訊",
      placeholder: "您的電郵",
      button: "訂閱"
    }
  },
}