import React, { useState, useRef, useContext } from 'react'
// import ModalVideo from 'react-modal-video'

import { PageLocaleContext } from '../../../Layouts/index';
// import Layout from '../../Layouts/en'
// import Button_01 from '../components/Button/Button_01/Button_01'
import Button from '../../Button/Button'
import VideoPlayer from '../../VideoPlayerYoutube/index'
import EmailSubscribe from '../../EmailSubscribe/EmailSubscribe'
import ModalVideoParent from '../../Modal/Loadable'
// import { kotiImageAltGenerator, kotiTitleGenerator } from '../utils'
import SEO from '../../SEO'
import { delightYouTubeId, cocoonYouTubeId } from '../../../Constants';


import ToolTipsIcon from '../../../components/TooltipsIcon/TooltipsIcon'
import playButtonTriangle from '../../../assets/img/icon/play-button-triangle.svg'

// platform icon
import googleAssistantIcon from '../../../assets/img/icon/platform-logo/google-assistant-logo-s.png'
import tencentXiaoWeiIcon from '../../../assets/img/icon/platform-logo/tencent-xiaowei-logo-s.png'
import alexaIcon from '../../../assets/img/icon/platform-logo/alexa-logo-s.png'
import xiaoaiIcon from '../../../assets/img/icon/platform-logo/xiaoai-logo-s.png'
import cortanaIcon from '../../../assets/img/icon/platform-logo/cortana-logo-s.png'
import bixbyIcon from '../../../assets/img/icon/platform-logo/bixby-logo-s.png'
import whatsappIcon from '../../../assets/img/icon/platform-logo/whatsapp-logo-s.png'
import wechatIcon from '../../../assets/img/icon/platform-logo/wechat-logo-s.png'
import fbMessengerIcon from '../../../assets/img/icon/platform-logo/facebook-messenger-logo-s.png'
import lineIcon from '../../../assets/img/icon/platform-logo/line-logo-s.png'
import telegramIcon from '../../../assets/img/icon/platform-logo/telegram-logo-s.png'
import kakaotalkIcon from '../../../assets/img/icon/platform-logo/kakaotalk-logo-s.png'
import appleBusinessChatIcon from '../../../assets/img/icon/platform-logo/apple-business-chat-logo-s.png'
import siriIcon from '../../../assets/img/icon/platform-logo/siri-logo-s.png'

// company photo
import cocoonImg from '../../../assets/img/img/cocoon-02.jpg'
import hshImg from '../../../assets/img/img/hai-sang-hong-02.jpg'
import churchImg from '../../../assets/img/img/church-02.jpg'
import igsoImg from '../../../assets/img/img/igso-02.jpg'
import outwhizImg from '../../../assets/img/img/outwhiz-02.jpg'
import stbImg from '../../../assets/img/img/stb-02.jpg'

// company logo
import stbLogo from '../../../assets/img/icon/stb-logo-s.png'
import jewelLogo from '../../../assets/img/icon/jewel-logo-s.png'
import cocoonLogo from '../../../assets/img/icon/cocoon-logo-s.png'
import rocketLogo from '../../../assets/img/icon/rocket-eyewear-logo-s.png'
import cocoonWhiteLogo from '../../../assets/img/icon/cocoon-white-logo.png'

// image 
import supportedDeviceImg from '../../../assets/img/img/supported-device.png'
import dashboardImg from '../../../assets/img/img/dashboard-discovery-sample.jpg'
import apiImage from '../../../assets/img/img/api_code.jpg'
import ericaMa from '../../../assets/img/img/erica-ma-01.jpg'



import { indexPageData } from './constants'

import '../../../sass/base/_utility.scss'
import './index.scss'

// const cocoonLink = "https://www.youtube.com/watch?v=6AfR7YuBwsg&feature=youtu.be"

const IndexPage = () => {
  const [isOpen, setOpen] = useState(false) // state for the video modal
  const { langProperty } = useContext(PageLocaleContext)
  const langKey = langProperty.currentLangKey
  const langKeyInPath = langProperty.languageKeyInPath
  const data = indexPageData[langKey]

  const case1 = useRef(null)
  const case2 = useRef(null)
  const case3 = useRef(null)
  const case4 = useRef(null)
  const case5 = useRef(null)
  const case6 = useRef(null)

  const button1 = useRef(null)
  const button2 = useRef(null)
  const button3 = useRef(null)
  const button4 = useRef(null)
  const button5 = useRef(null)
  const button6 = useRef(null)

  const cases = [case1, case2, case3, case4, case5, case6]
  const buttons = [button1, button2, button3, button4, button5, button6]

  const onMouseOverOption = i => {
    // console.log(e.target.classList)
    // console.log(i)
    cases.forEach(el => el.current.classList.remove('active'))
    buttons.forEach(el => el.current.classList.remove('active'))
    cases[i].current.classList.add('active')
    buttons[i].current.classList.add('active')
  }



//   <iframe 
//   title="delight-video" 
//   width="560" 
//   height="315" 
//   src="https://www.youtube.com/embed/pS9ybHBzZXQ?controls=1&showinfo=0&rel=0&modestbranding=1" 
//   frameborder="0" 
//   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope" 
//   allowfullscreen
//   sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
//   >
// </iframe>
// {/* <iframe
//         width="640"
//         height="390"
//         src="https://www.youtube.com/embed/VgC4b9K-gYU"
//         sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation">
// </iframe> */}


  return (
    <>
      <SEO
        title='Home | Delight'
        description="Transform your user experience with voice"
      />
      <div className={`page-index ${langKey}`}>
        {/* SECTION HERO */}
        <div className="page-index__section-hero" id="top">
          <div className="page-index__section-hero__section-left">
            <div className="inner-wrapper">
              <h1 className={`header-h1 ${langKey}`}>{data.sectionHero.header}</h1>
              <div className="body-01-01">{data.sectionHero.tag}</div>
              <Button link="#email" className="light-bg">{data.sectionHero.heroBtn}</Button>
            </div>
          </div>
          <div className="page-index__section-hero__section-right">
            <VideoPlayer videoId={delightYouTubeId} />
          </div>
        </div>

        {/* section any */}
        <div className="page-index__section-any">
          <div className="fullwidth-section-container-01">
            <div className="fullwidth-section-container-02">
              <div className="page-index__section-any__section-wrapper">
                <div className="page-index__section-any__section-wrapper__inner-stack-01">
                  <div className="page-index__section-any__section-wrapper__inner-stack-01__section-left">
                    <img src={supportedDeviceImg} alt="Delight's supported devices"/>
                  </div>
                  <div className="page-index__section-any__section-wrapper__inner-stack-01__section-right">
                    <h2 className={`header-h4 ${langKey}`}>{data.sectionAny.header}</h2>
                    <div className="body-01-01">{data.sectionAny.tag}</div>
                  </div>
                </div>
                <div className="page-index__section-any__section-wrapper__platforms">
                  <div className="img-wrapper">
                    <ToolTipsIcon tooltips={data.sectionAny.channels.google}><img className="round-icon" src={googleAssistantIcon} alt="google assistant"/></ToolTipsIcon>
                  </div>
                  <div className="img-wrapper">
                  <ToolTipsIcon tooltips={data.sectionAny.channels.tencent}><img src={tencentXiaoWeiIcon} alt="tencent xiaowei"/></ToolTipsIcon>
                  </div>     
                  <div className="img-wrapper">
                    <ToolTipsIcon tooltips={data.sectionAny.channels.alexa}><img className="round-icon" src={alexaIcon} alt="alexa"/></ToolTipsIcon> 
                  </div>
                  <div className="img-wrapper">
                    <ToolTipsIcon tooltips={data.sectionAny.channels.siri}><img className="round-icon" src={siriIcon} alt="siri"/></ToolTipsIcon>
                  </div>        
                  <div className="img-wrapper">
                    <ToolTipsIcon tooltips={data.sectionAny.channels.xiaomi}><img className="round-icon" src={xiaoaiIcon} alt="xiaoai"/></ToolTipsIcon>
                  </div>     
                  <div className="img-wrapper">
                    <ToolTipsIcon tooltips={data.sectionAny.channels.cortana}><img className="round-icon" src={cortanaIcon} alt="cortana"/></ToolTipsIcon>
                  </div>     
                  <div className="img-wrapper">
                    <ToolTipsIcon tooltips={data.sectionAny.channels.bixby}><img className="round-icon" src={bixbyIcon} alt="bixby"/></ToolTipsIcon>
                  </div>     
                  <div className="img-wrapper">
                    <ToolTipsIcon tooltips={data.sectionAny.channels.whatsapp}><img className="round-icon" src={whatsappIcon} alt="whatsapp"/></ToolTipsIcon>
                  </div>     
                  <div className="img-wrapper">
                    <ToolTipsIcon tooltips={data.sectionAny.channels.wechat}><img src={wechatIcon} alt="wechat"/></ToolTipsIcon>
                  </div>     
                  <div className="img-wrapper">
                    <ToolTipsIcon tooltips={data.sectionAny.channels.fb}><img className="round-icon" src={fbMessengerIcon} alt="facebook messenger"/></ToolTipsIcon>
                  </div>     
                  <div className="img-wrapper">
                    <ToolTipsIcon tooltips={data.sectionAny.channels.line}><img src={lineIcon} alt="line"/></ToolTipsIcon>
                  </div>
                  <div className="img-wrapper">
                    <ToolTipsIcon tooltips={data.sectionAny.channels.telegram}><img className="round-icon" src={telegramIcon} alt="telegram"/></ToolTipsIcon>
                  </div>    
                  <div className="img-wrapper">
                    <ToolTipsIcon tooltips={data.sectionAny.channels.kakao}><img src={kakaotalkIcon} alt="kakaotalk"/></ToolTipsIcon>
                  </div>     
                  <div className="img-wrapper">
                    <ToolTipsIcon tooltips={data.sectionAny.channels.apple}><img className="round-icon" src={appleBusinessChatIcon} alt="apple business chat"/></ToolTipsIcon>
                  </div>     
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* section dashboard */}
        <div className="page-index__section-dashboard">
          <div className="page-index__section-dashboard__section-left">
            <div className="wrapper">
              <h2 className="header-h4">{data.sectionDashboard.header}</h2>
              <ul>
                <li className="body-01-02"><i className="fas fa-pencil-alt"></i>{data.sectionDashboard.pt1}</li>
                <li className="body-01-02"><i className="far fa-clock"></i>{data.sectionDashboard.pt2}</li>
                <li className="body-01-02"><i className="fas fa-chart-bar"></i>{data.sectionDashboard.pt3}</li>
              </ul>
            </div>
          </div>
          <div className="page-index__section-dashboard__section-right">
            <img src={dashboardImg} alt="dashboard"/>
          </div>
        </div>

        {/* SECTION CASE */}
        <div className="page-index__section-cases">
          <div className="fullwidth-section-container-01">
            <div className="fullwidth-section-container-02">
              <div className="page-index__section-cases__section-wrapper">
                <div className="page-index__section-cases__section-wrapper__top-section-wrapper">
                  <div className="page-index__section-cases__section-wrapper__top-section-wrapper__section-left">
                    <h2 className="header-h4 mobile">{data.sectionCase.header}</h2>
                    <div className="body-01-01 mobile">{data.sectionCase.tag}</div>
                    <div className="case active" ref={case1}><img src={cocoonImg} alt="Cocoon"/></div>
                    <div className="case" ref={case2}><img src={hshImg} alt="Hai Sang Hong"/></div>
                    <div className="case" ref={case3}><img src={churchImg} alt="Church"/></div>
                    <div className="case" ref={case4}><img src={igsoImg} alt="IGSO"/></div>
                    <div className="case" ref={case5}><img src={outwhizImg} alt="Outwhiz"/></div>
                    <div className="case" ref={case6}><img src={stbImg} alt="Singapore Tourism Board"/></div>
                  </div>
                  <div className="page-index__section-cases__section-wrapper__top-section-wrapper__section-right">
                    <h2 className="header-h4 desktop">{data.sectionCase.header}</h2>
                    <div className="body-01-01 desktop">{data.sectionCase.tag}</div>
                    <div className="cases">
                      <div className="menu-text-01 active" role="link" ref={button1} onMouseOver={() => onMouseOverOption(0)} onFocus={ () => void 0 } tabIndex="0" >{data.sectionCase.case1}</div><br/>
                      <div className="menu-text-01" role="link" ref={button2} onMouseOver={() => onMouseOverOption(1) } onFocus={ () => void 0 } tabIndex="0" >{data.sectionCase.case2}</div><br/>
                      <div className="menu-text-01" role="link" ref={button3} onMouseOver={() => onMouseOverOption(2) } onFocus={ () => void 0 } tabIndex="0" >{data.sectionCase.case3}</div><br/>
                      <div className="menu-text-01" role="link" ref={button4} onMouseOver={() => onMouseOverOption(3) } onFocus={ () => void 0 } tabIndex="0" >{data.sectionCase.case4}</div><br/>
                      <div className="menu-text-01" role="link" ref={button5} onMouseOver={() => onMouseOverOption(4) } onFocus={ () => void 0 } tabIndex="0" >{data.sectionCase.case5}</div><br/>
                      <div className="menu-text-01" role="link" ref={button6} onMouseOver={() => onMouseOverOption(5) } onFocus={ () => void 0 } tabIndex="0" >{data.sectionCase.case6}</div>
                    </div>
                  </div>
                </div>
                <div className="page-index__section-cases__section-wrapper__company-list">
                  <div className="meta-heading-02">{data.sectionCase.trustedBy}</div>
                  <div className="img-wrapper">
                    <img src={stbLogo} alt="Singapore tourism board"/>
                  </div>
                  <div className="img-wrapper">
                    <img src={jewelLogo} alt="Jewel"/>
                  </div>
                  <div className="img-wrapper">
                    <img src={cocoonLogo} alt="Cocoon"/>
                  </div>
                  <div className="img-wrapper">
                    <img src={rocketLogo} alt="Rocket Eyewear"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* SECTION API */}
        <div className="page-index__section-api">
          <div className="page-index__section-api__section-left">
            <div className="wrapper {}">
              <h2 className="header-h4">{data.sectionAPI.header}</h2>
              <ul>
                {data.sectionAPI.features.map(feature => (
                  <li key={feature} className={`body-01-02 ${langKey}`}><i className="fas fa-check-circle"></i>{feature}</li>
                ))}
              </ul>
              <Button type="normal" link={`${langKeyInPath}/api`} elementStyle="light-bg" text={data.sectionAPI.button} />
            </div>
          </div>
          <div className="page-index__section-api__section-right">
            <img src={apiImage} alt="vs-code"/>
          </div>
        </div>

        {/* SECTION TESTIMONIAL */}
        <div className="page-index__section-testimonial">
          <div className="page-index__section-testimonial__section-left">
            <img src={ericaMa} alt="cocoon-testimonial"/>
          </div>
          <div className="page-index__section-testimonial__section-right">
            <div className="section-inner-wrapper">
              <blockquote className="quote-body-01">{data.sectionTestimonal.quote}</blockquote>
              <img className="company-logo" src={cocoonWhiteLogo} alt="cocoon logo"/>
              <div className="quote-author-01">{data.sectionTestimonal.author}</div>
              <Button 
                className="testimonial-btn" 
                type="borderline" 
                icon={playButtonTriangle} 
                onClickCallback={()=> setOpen(true)} 
                text={data.sectionTestimonal.button} />
              <ModalVideoParent isOpen={isOpen} setClose={() => setOpen(false)} className="modal-video-parent">
                <VideoPlayer videoId={cocoonYouTubeId} />
              </ModalVideoParent>
              {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="6AfR7YuBwsg" onClose={() => setOpen(false)} /> */}
            </div>
          </div>
        </div>

        <div className={`page-index__section-bottom ${langKey}`} id="email">
          <div className="fullwidth-section-container-01">
              <div className="fullwidth-section-container-02">
                <h3 className="header-h3-white">{data.sectionEmail.header}</h3>
                <EmailSubscribe 
                  title = ""
                  placeholderText = {data.sectionEmail.placeholder}
                  buttonText = {data.sectionEmail.button}
                  langKey = {langKey}
                />
                {/* <Button_01 className="btn-mobile" href="/contact" name="contact us"/> */}
              </div>
            </div>
          </div>
      </div>
    </>
  )
}

export default IndexPage
