import React from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
// import { Label } from 'reactstrap'
import './EmailSubscribe.scss'

// need to add more audience in mailchimp for different languages
const subscribeUrl = {
  "en": "//fb.us19.list-manage.com/subscribe/post?u=c5a279fcb669e6b1539efdff1&id=fb945c58da",
  "zh": "//fb.us19.list-manage.com/subscribe/post?u=c5a279fcb669e6b1539efdff1&id=fb945c58da",
  "zh-hk": "//fb.us19.list-manage.com/subscribe/post?u=c5a279fcb669e6b1539efdff1&id=fb945c58da",
}


export default class EmailSubscribe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      EMAIL: '',
      FNAME: '',
      LNAME: ''
    }
  }

  submitForm = (e, subscribe) => {
    e.preventDefault()

    if (this.state.EMAIL) {
      subscribe(this.state)
      this.setState({
        EMAIL: '',
        FNAME: '',
        LNAME: ''
      })
    }
  }

  onChangeEmail = e => {
    this.setState({
      ...this.state,
      EMAIL: e.target.value,
    })
  }

  onChangeFirstName = e => {
    this.setState({
      ...this.state,
      FNAME: e.target.value,
    })
  }

  onChangeLastName= e => {
    this.setState({
      ...this.state,
      LNAME: e.target.value,
    })
  }

  render() {

    return (
      <div id="email-subscribe" className="email-subscribe">
          {/* <h2 className="header-h2">{this.props.title}</h2> */}
          <MailchimpSubscribe
            url={subscribeUrl[this.props.langKey]}
            render={({ subscribe, status, message }) => (
              <form
                // className="row justify-content-center form--active"
                // style={{marginLeft: 0}}
                // data-success="Thanks for signing up.  Please check your inbox for a confirmation email."
                // data-error="Please provide your email address and agree to the terms."
                onSubmit={e => this.submitForm(e, subscribe)}
              >
                {/* <div className="form-field-wrapper">
                  <label for="first-name">
                    First Name
                  </label>
                  <input
                    id="first-name"
                    className="validate-required validate-firstname"
                    type="text"
                    name="FNAME"
                    value={this.state.FNAME}
                    onChange={this.onChangeFirstName}
                    placeholder="First Name"
                    required
                  />
                </div>
                <div className="form-field-wrapper">
                  <label for="last-name">
                    Last name
                  </label>
                  <input
                    id="last-name"
                    className="validate-required validate-email"
                    type="text"
                    name="LNAME"
                    value={this.state.LNAME}
                    onChange={this.onChangeLastName}
                    placeholder="Last Name"
                    required
                  />
                </div> */}
                <div className="form-field-wrapper">
                  {/* <label for="email">
                    Email
                  </label> */}
                  <input
                    id="email"
                    className="validate-required validate-email form-text"
                    type="email"
                    name="EMAIL"
                    value={this.state.EMAIL}
                    onChange={this.onChangeEmail}
                    placeholder={this.props.placeholderText}
                    required
                  />
                  <div className="msg-wrapper">
                    {status === 'success' && (
                      <div
                        className="subscribe-success"
                        // style={{ color: 'green' }}
                        dangerouslySetInnerHTML={{ __html: message }}
                      />
                    )}
                    {status === 'error' && (
                      <div
                        className="subscribe-fail"
                        // style={{ color: 'red' }}
                        dangerouslySetInnerHTML={{ __html: message }}
                      />
                    )}
                  </div>
                </div>
            
                {/* add a spacing only visible to xs so the button has margin-top only in mobile screen */}
                <div className="button-wrapper">
                  <button
                    className="button-01 button light-bg"
                    type="submit"
                    // style={{marginTop: 0}} // remove marginTop for this case
                    disabled={status === 'sending'}
                  >
                    {this.props.buttonText}
                  </button>
                </div>
                <div className="msg-wrapper msg-wrapper-mobile">
                    {status === 'success' && (
                      <div
                        className="subscribe-success"
                        // style={{ color: 'green' }}
                        dangerouslySetInnerHTML={{ __html: message }}
                      />
                    )}
                    {status === 'error' && (
                      <div
                        className="subscribe-fail"
                        // style={{ color: 'red' }}
                        dangerouslySetInnerHTML={{ __html: message }}
                      />
                    )}
                  </div>
              </form>
            )}
          />
      </div>
    )
  }
}


// <form
//   // className="row justify-content-center form--active"
//   // style={{marginLeft: 0}}
//   // data-success="Thanks for signing up.  Please check your inbox for a confirmation email."
//   // data-error="Please provide your email address and agree to the terms."
//   onSubmit={e => this.submitForm(e, subscribe)}
// >
//   {}
//   {/* <div className="form-field-wrapper">
//     <label for="first-name">
//       First Name
//     </label>
//     <input
//       id="first-name"
//       className="validate-required validate-firstname"
//       type="text"
//       name="FNAME"
//       value={this.state.FNAME}
//       onChange={this.onChangeFirstName}
//       placeholder="First Name"
//       required
//     />
//   </div>
//   <div className="form-field-wrapper">
//     <label for="last-name">
//       Last name
//     </label>
//     <input
//       id="last-name"
//       className="validate-required validate-email"
//       type="text"
//       name="LNAME"
//       value={this.state.LNAME}
//       onChange={this.onChangeLastName}
//       placeholder="Last Name"
//       required
//     />
//   </div> */}
//   <div className="form-field-wrapper">
//     {/* <label for="email">
//       Email
//     </label> */}
//     <input
//       id="email"
//       className="validate-required validate-email form-text"
//       type="email"
//       name="EMAIL"
//       value={this.state.EMAIL}
//       onChange={this.onChangeEmail}
//       placeholder={this.props.placeholderText}
//       required
//     />
//     <div className="msg-wrapper">
//       {status === 'success' && (
//         <div
//           className="subscribe-success"
//           // style={{ color: 'green' }}
//           dangerouslySetInnerHTML={{ __html: message }}
//         />
//       )}
//       {status === 'error' && (
//         <div
//           className="subscribe-fail"
//           // style={{ color: 'red' }}
//           dangerouslySetInnerHTML={{ __html: message }}
//         />
//       )}
//     </div>
//   </div>

//   {/* add a spacing only visible to xs so the button has margin-top only in mobile screen */}
//   <div className="button-wrapper">
//     <button
//       className="button-01 button light-bg"
//       type="submit"
//       // style={{marginTop: 0}} // remove marginTop for this case
//       disabled={status === 'sending'}
//     >
//       {this.props.buttonText}
//     </button>
//   </div>
//   <div className="msg-wrapper msg-wrapper-mobile">
//       {status === 'success' && (
//         <div
//           className="subscribe-success"
//           // style={{ color: 'green' }}
//           dangerouslySetInnerHTML={{ __html: message }}
//         />
//       )}
//       {status === 'error' && (
//         <div
//           className="subscribe-fail"
//           // style={{ color: 'red' }}
//           dangerouslySetInnerHTML={{ __html: message }}
//         />
//       )}
//     </div>
// </form>