import React from 'react';
import './TooltipsIcon.scss';

const TooltipsIcon = ({tooltips, children}) => {
  return (
    <div className="tooltipsIcon">
      {children}
      <div className="tooltips-text">{tooltips}</div>
    </div>
  )
}

export default TooltipsIcon
